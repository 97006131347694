/* eslint-disable camelcase */
import React, { MouseEventHandler, useEffect } from "react";
import { prepareSnSdk } from "../utils/util";
import {
  handleTrackingOnClickOfImage,
} from "../utils/analytics";
import { SocialNativeProps } from "../types/types";
import { getTracking } from "@vp/tracking";
import { Button, Icon } from "@vp/swan";

const SocialNative: React.FC<SocialNativeProps> = ({ widgetData, analyticsData, locale }) => {
  const scriptPath = "https://photorankstatics-a.akamaihd.net/743d2e78a76dedeb07e0745158547931/static/frontend/latest/build.min.js";

  useEffect(() => {
    // Create a <style> element
    const style = document.createElement('style');
    style.innerHTML = `
      .olapic-slider-wrapper {
        margin: 0 !important;
        width: auto !important;
      }
      
      .instagram_graph{
        height: 390px !important;
        width: 390px !important;
      }      

      #social-curated-content .olapic-nav-button {
        display: none !important;
      }
      
      #social-curated-content .olapic-slider-footer{
        display: none !important;
      }

      #social-curated-content .instagram_graph {
        background-color: var(--swan-sem-color-bg-container);
        border-color: var(--swan-sem-color-border-container);
        border-radius: var(--swan-sem-border-radius-container);
        border-style: solid;
        border-width: var(--swan-sem-border-width-none);
      }

      #social-curated-content .social-curation-nav-prev {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 99;
        transform: translate(-50%, -50%);
      }

      #social-curated-content .social-curation-nav-next{
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 99;
        transform: translate(50%, -50%);
      }

      @media (max-width: 1024px) {
        #social-curated-content .social-curation-nav-prev,
        #social-curated-content .social-curation-nav-next {
          transform: translate(0%, -50%);
        }
      }
    `;

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const olapicScript = document.querySelector("[data-olapic='olapic_specific_widget']");

    if (typeof window !== "undefined" && !olapicScript) {
      const olapicScript = document.createElement("script");
      olapicScript.setAttribute("type", "text/javascript");
      olapicScript.setAttribute("src", scriptPath);
      olapicScript.setAttribute("data-olapic", "olapic_specific_widget");
      olapicScript.setAttribute("data-instance", widgetData.widget.instanceId);
      olapicScript.setAttribute("data-apikey", widgetData.apiKey);
      olapicScript.setAttribute("data-tags", widgetData.source.tag);
      olapicScript.setAttribute("async", "async");

      olapicScript.onerror = (error) => {
        console.error(`Error: SN script is not loaded. Page: ${analyticsData.pageName}, Page Url: ${window.location.href}, Error: ${JSON.stringify(error)}`, {
          contextData: widgetData,
        });
      };
      document.head.appendChild(olapicScript);
    }

    // check if olapic data is set in window or not , if not it will send data to olapicSDK
    if (typeof window !== "undefined" && !window.olapic) {
      window.onOlapicLoad = () => {
        try {
          window.OlapicSDK.conf.set("apikey", widgetData?.apiKey);
          window.olapic = window.olapic || new window.OlapicSDK.Olapic(() => prepareSnSdk(widgetData));
        } catch (error) {
          console.error(`Error: Setting SN object to window object. Page: ${analyticsData.pageName}, Page Url: ${window.location.href}, Error: ${error}`, {
            contextData: widgetData,
          });
        }
      };
    }

    // remove the script tag when the component is unmounted
    return () => {
      if (olapicScript) {
        document.head.removeChild(olapicScript);
      }
    };
  }, [widgetData]);

  const initiateAnalyticsEvent = (eventName: string, trackingData: Record<string, unknown>) => {    
    return new Promise<void>((resolve, reject) => {
      try {
        const tracking = getTracking()
        if (tracking) {
          tracking.track(eventName, trackingData)
        } else {
          const options: AddEventListenerOptions = { once: true }
          window.addEventListener(
            "trackingReady",
            () => {
              const newTracking = getTracking()
              newTracking?.track(eventName, trackingData)
            },
            options
          )
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    handleTrackingOnClickOfImage(event.nativeEvent, analyticsData, initiateAnalyticsEvent);
  };

  const next = () => {
    const nextButton = document.querySelector('.olapic-nav-next') as HTMLElement
    if (nextButton) nextButton.click();
  }
  const prev = () => {
    const prevButton = document.querySelector('.olapic-nav-prev') as HTMLElement
    if (prevButton) prevButton.click();
  }
  return (
    widgetData && analyticsData && locale ? (
      <div data-testid="olapic_product_feed" id="social-curated-content" style={{ position: 'relative' }}>

        <Button buttonShape="round" onClick={prev} className="social-curation-nav-prev">
          <Icon alt="image" iconType="chevronLeft" />
        </Button>
        <div
          id="olapic_specific_widget"
          onClick={handleClick}
        />
        <Button buttonShape="round" onClick={next} className="social-curation-nav-next">
          <Icon alt="image" iconType="chevronRight" />
        </Button>   </div>
    ) : null
  );
};
export { SocialNative };
