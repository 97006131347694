/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { AnalyticsInterface } from "../types/types";

type TrackingData = Record<string, string> | Record<string, unknown>;
type AnalyticsFunction = (eventName: string, trackingData: TrackingData) => void;

export const getPhotoPositionForAnalytics = (target: HTMLElement) => {
  let selectedPhotoIndex = -1;
  let totalPhotoElements = 10;
  while (target && target.tagName.toLowerCase() !== "li") {
    target = target.parentElement as HTMLElement;
  }

  if (target && target.tagName.toLowerCase() === "li") {
    const parentPhotosUlElement = target.parentElement as HTMLElement;

    if (parentPhotosUlElement && parentPhotosUlElement.tagName.toLowerCase() === "ul") {
      totalPhotoElements = parentPhotosUlElement.children.length;
      selectedPhotoIndex = Array.from(parentPhotosUlElement.children).indexOf(target);
    }
  }
  return { selectedPhotoIndex, totalPhotoElements };
};

export const handleTrackingOnClickOfImage = (
  event: MouseEvent,
  analyticsData: AnalyticsInterface,
  analyticsFun: AnalyticsFunction,
) => {
  let target = event.target as HTMLElement;
  
  // Iterate through the DOM tree until the target with the 'data-olapic-photo-id' attribute is found
  while (target && !target.hasAttribute("data-olapic-photo-id")) {
    target = target.parentElement as HTMLElement;
  }

  // Extract photo details for analytics
  const photoDetails = getPhotoPositionForAnalytics(target);
  
  if (photoDetails) {
    const { selectedPhotoIndex, totalPhotoElements } = photoDetails;
    const username = (target && target.querySelector(".olapic-user-name")?.textContent) ?? "";
    const label = `${username}:${selectedPhotoIndex}_${totalPhotoElements}`;    
    const analyticsDataToPass = {
      category: "Social Native Feed",
      label,
      ...analyticsData,
    };
    analyticsFun("Carousel Clicked", analyticsDataToPass);
  }
};
