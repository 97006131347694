/* eslint-disable camelcase */
import { SocialNativeProps } from "../types/types";

export const renderOlapicProductCards = (options: {
  id: string;
  tags: string;
  wrapper: string;
}) => {
  window.olapic.prepareWidget(options, {
    renderNow: true,
    force: true,
  });
};

export const prepareSnSdk = (data: SocialNativeProps["widgetData"]) => {
  if (typeof window !== "undefined" && window.olapic) {
    renderOlapicProductCards({
      id: data.widget.instanceId,
      tags: data.source.tag,
      wrapper: "olapic_specific_widget",
    });
  }
};
